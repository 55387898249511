(<template>
  <setting-page-skeleton :show-mob-navigation="showMobNav"
                         :expanded="isBuyerInvoicesRoute">
    <sidebar-nav />
  </setting-page-skeleton>
</template>)

<script>
  import SidebarNav from '@/components/enterprise/EnterpriseNav';
  import PageDataRoot from '@/components/shared_components/page_data/PageDataRoot';

  export default {
    components: {
      'sidebar-nav': SidebarNav
    },
    extends: PageDataRoot,
    computed: {
      showMobNav() { return this.$route.name == 'BuyerCompany'; },
      isBuyerInvoicesRoute() { return this.$route.name == 'BuyerInvoices'; }
    }
  };
</script>
