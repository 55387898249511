<script>
  import PageDataSidebar from '@/components/shared_components/page_data/PageDataSidebar';
  import {mapGetters} from 'vuex';

  export default {
    extends: PageDataSidebar,
    computed: {
      ...mapGetters('UserInfoStore', ['showInvoicesPage']),
      mobTitle() { return this.$gettext('My company'); },
      navLinksList() {
        const links = [{
          title: this.$gettext('Company information'),
          route: {name: 'BuyerCompanyInfo'}
        }, {
          title: this.$gettext('Employees'),
          route: {
            name: 'BuyerEmployees',
            query: {
              page: 1
            }
          }
        }, {
          title: this.$gettext('Departments'),
          route: {
            name: 'BuyerDepartments',
            query: {
              page: 1
            }
          }
        }, {
          title: this.$gettext('Addresses'),
          route: {name: 'BuyerAddresses'}
        }, {
          title: this.$gettext('Payment methods'),
          route: {name: 'BuyerPaymentMethods'}
        }, {
          title: this.$gettext('Security'),
          route: {name: 'BuyerSecurity'}
        }];

        if (this.showInvoicesPage) {
          links.splice(5, 0, {
            title: this.$gettext('Invoices'),
            route: {
              name: 'BuyerInvoices',
              query: {page: 1}
            }
          });
        }

        return links;
      }
    }
  };
</script>
